import React from 'react'

export default function Strapline ({heading = 'Some heading', text = null}) {

  return (
    <section className="strapline">
      <h1 className="heading">{heading}</h1>
      {text && text}
    </section>
  )
}
